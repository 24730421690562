import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import api from './api'; // Import API module
import './UserProfile.css'; // Import custom CSS for styling

const UserProfile = () => {
  const [profile, setProfile] = useState(null);
  const { authState, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      if (authState.isAuthenticated && authState.user && authState.user.sub) {
        const userId = authState.user.sub; // Use the user ID from authState
        try {
          const response = await api.get(`/users/${userId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          setProfile(response.data);
        } catch (error) {
          console.error('Error fetching profile', error);
          if (error.response && error.response.status === 401) {
            // Token might be invalid or expired
            logout();
            navigate('/login');
          }
        }
      }
    };

    fetchProfile();
  }, [authState.isAuthenticated, authState.user, logout, navigate]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleEditProfile = () => {
    if (authState.user.role === 'administrator') {
      navigate('/admin/profile');
    } else {
      navigate('/profile');
    }
  };

  if (!authState.isAuthenticated || !profile) {
    return null;
  }

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  return (
    <Dropdown align="end">
      <Dropdown.Toggle as="div" className="custom-profile-toggle"> {/* Uklanja defaultne stilove */}
        {profile.profileImage && profile.profileImage !== 'null' ? (
          <img
            src={`${serverUrl}/profileImage/${profile.profileImage}`}
            alt={`${profile.username}`}
            className="user-profile-image"
          />
        ) : (
          <div className="user-no-image-container">
            <div className="user-no-image-circle">
              <span className="username-initial">{profile.username[0].toUpperCase()}</span> {/* Dodano slovo */}
            </div>
          </div>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={handleEditProfile}>Edit Profile</Dropdown.Item>
        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserProfile;
